import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/Layout'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
Vue.use(VueRouter)

const routes = [
  // { path: '/', redirect: '/login' },
  { path: '/login', component: () => import('@/views/Login.vue') },
  // 首页
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        component: () => import('@/views/Home.vue'),
        meta: { title: '首页' }
      }
    ]
  },
  // 用户管理
  {
    path: '/user',
    component: Layout,
    meta: { title: '用户管理' },
    children: [
      {
        path: 'Message',
        component: () => import('@/views/user/Message'),
        meta: { title: '消息管理' }
      },
      {
        path: 'Contactt/:id',
        component: () => import('@/views/user/Contactt')
      },
      {
        path: 'userlist',
        component: () => import('@/views/user/List'),
        meta: { title: '客户列表' }
      },
      {
        path: 'online',
        component: () => import('@/views/user/Online'),
        meta: { title: '在线用户' }
      },
      {
        path: 'balancelist',
        component: () => import('@/views/user/Balance'),
        meta: { title: '客户余额' }
      },
      {
        path: 'loginlog',
        component: () => import('@/views/user/Loginlog'),
        meta: { title: '客户操作记录' }
      },
      {
        path: 'assetslog',
        component: () => import('@/views/user/Assetslog'),
        meta: { title: '资产操作记录' }
      },
      {
        path: 'codelist',
        component: () => import('@/views/user/Codelist'),
        meta: { title: '客户验证码记录' }
      },
      {
        path: 'contact',
        component: () => import('@/views/user/Payment'),
        meta: { title: '付款与联系方式' }
      },
      {
        path: 'rechargelog',
        component: () => import('@/views/user/Recharge'),
        meta: { title: '充值列表' }
      },
      {
        path: 'withdrawlog',
        component: () => import('@/views/user/Withdraw'),
        meta: { title: '提现列表' }
      }
    ]
  },
  // 钱包管理
  {
    path: '/wallet',
    component: Layout,
    meta: { title: '钱包管理' },
    children: [
      {
        path: 'walletlist',
        component: () => import('@/views/wallet/List'),
        meta: { title: '钱包信息' }
      },
      {
        path: 'typelist',
        component: () => import('@/views/wallet/Type'),
        meta: { title: '钱包类型' }
      }
    ]
  },
  // 产品管理
  {
    path: '/product',
    component: Layout,
    meta: { title: '产品管理' },
    children: [
      {
        path: 'productlist',
        component: () => import('@/views/product/List'),
        meta: { title: '产品列表' }
      },
      {
        path: 'typelist',
        component: () => import('@/views/product/Type'),
        meta: { title: '产品类型' }
      },
      {
        path: 'risk',
        component: () => import('@/views/product/Risk'),
        meta: { title: '风控管理' }
      }
    ]
  },
  // 系统设置
  {
    path: '/system',
    component: Layout,
    meta: { title: '系统设置' },
    children: [
      {
        path: 'adduser',
        component: () => import('@/views/system/Adduser'),
        meta: { title: '添加用户或管理员' }
      },
      {
        path: 'managelist',
        component: () => import('@/views/system/Manage'),
        meta: { title: '管理员列表' }
      },
      {
        path: 'arealist',
        component: () => import('@/views/system/Area'),
        meta: { title: '区号' }
      },
      {
        path: 'rolelist',
        component: () => import('@/views/system/Role'),
        meta: { title: '角色管理' }
      },
      {
        path: 'bdgoogle',
        component: () => import('@/views/system/Google'),
        meta: { title: '绑定GOOGLE' }
      },
      {
        path: 'agenturl',
        component: () => import('@/views/system/Agenturl'),
        meta: { title: '代理链接' }
      }
    ]
  },
  // 参数配置
  {
    path: '/config',
    component: Layout,
    meta: { title: '参数配置' },
    children: [
      {
        path: 'baseconfig',
        component: () => import('@/views/config/Base'),
        meta: { title: '基础配置' }
      },
      {
        path: 'paramsconfig',
        component: () => import('@/views/config/Params'),
        meta: { title: '参数配置' }
      },
      {
        path: 'carouselconfig',
        component: () => import('@/views/config/Carousel'),
        meta: { title: '轮播图设置' }
      },
      {
        path: 'audioconfig',
        component: () => import('@/views/config/Audio'),
        meta: { title: '音频设置' }
      },
      {
        path: 'emailconfig',
        component: () => import('@/views/config/Email'),
        meta: { title: '邮箱参数设置' }
      },
      {
        path: 'smsconfig',
        component: () => import('@/views/config/Sms'),
        meta: { title: '短信参数设置' }
      },
      {
        path: 'ipconfig',
        component: () => import('@/views/config/Ip'),
        meta: { title: 'IP地址解析设置' }
      },
      {
        path: 'paymentconfig',
        component: () => import('@/views/config/Payment'),
        meta: { title: '付款与联系方式设置' }
      },
      {
        path: 'agreementconfig',
        component: () => import('@/views/config/Agreement'),
        meta: { title: '协议设置' }
      }
    ]
  },
  // 教程管理
  {
    path: '/help',
    component: Layout,
    meta: { title: '教程管理' },
    children: [
      {
        path: 'helptype',
        component: () => import('@/views/help/Type'),
        meta: { title: '教程分类' }
      },
      {
        path: 'helplist',
        component: () => import('@/views/help/List'),
        meta: { title: '教程列表' }
      }
    ]
  },
  // 行情管理
  {
    path: '/news',
    component: Layout,
    meta: { title: '行情管理' },
    children: [
      {
        path: 'newslist',
        component: () => import('@/views/news/List'),
        meta: { title: '新闻' }
      },
      {
        path: 'pushconfig',
        component: () => import('@/views/news/Push'),
        meta: { title: '新闻推送参数设置' }
      }
    ]
  },
  // 创新订单
  {
    path: '/orders',
    component: Layout,
    meta: { title: '创新订单' },
    children: [
      {
        path: 'orderlist',
        component: () => import('@/views/orders/List'),
        meta: { title: '交易流水' }
      }
    ]
  },
  // 买卖币管理
  {
    path: '/trade',
    component: Layout,
    meta: { title: '买卖币管理' },
    children: [
      {
        path: 'review',
        component: () => import('@/views/trade/Review'),
        meta: { title: '信息审核' }
      },
      {
        path: 'tradelist',
        component: () => import('@/views/trade/List'),
        meta: { title: '交易流水' }
      },
      {
        path: 'unit',
        component: () => import('@/views/trade/Unit'),
        meta: { title: '交易单位' }
      }
    ]
  },
  // 理财管理
  {
    path: '/fund',
    component: Layout,
    meta: { title: '基金管理' },
    children: [
      {
        path: 'infolist',
        component: () => import('@/views/fund/Info'),
        meta: { title: '理财基金' }
      },
      {
        path: 'publish',
        component: () => import('@/views/fund/Publish'),
        meta: { title: '基金发布' }
      },
      {
        path: 'fundlist',
        component: () => import('@/views/fund/List'),
        meta: { title: '基金投资' }
      },
      {
        path: 'storagelist',
        component: () => import('@/views/fund/Storage'),
        meta: { title: '理财记录' }
      },
      {
        path: 'description',
        component: () => import('@/views/fund/Description'),
        meta: { title: '基金介绍' }
      },
      {
        path: 'credit',
        component: () => import('@/views/fund/Credit'),
        meta: { title: '信用额度' }
      },
      {
        path: 'typelist',
        component: () => import('@/views/fund/Type'),
        meta: { title: '基金类型' }
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  // 设置标题
  if (to.meta.title) {
    document.title = to.meta.title + ' - 后台管理系统'
  } else {
    document.title = '后台管理系统'
  }
  // 如果访问登陆页 直接放行
  if (to.path === '/login') return next()
  // 获取token
  const tokenstr = window.sessionStorage.getItem('token')
  // if (!tokenstr) return next('/login')
  if (tokenstr) {
    next()
  } else {
    next(`/login?redirect=${to.path}`)
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
